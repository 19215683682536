import React, { useEffect, useState } from 'react';
import { getTransactionsStatus } from './api';
import { getBrowserContext } from './globals';
import { getDropInfo, getInfoFromTemplateId } from '@api/api';
import FiatTransactionCard from './FiatTransactionCard';
import { PACK_DROPS_LIST, WAX_SIGN_IN } from '@utils/globals';

let timeout = null;

function FiatHistory () {
    const [ data, setData ] = useState([]);
    const [ packsInfoList, setPacksInfoList ] = useState([]);
    const [ packsImages, setPacksImages ] = useState({});

    useEffect(() =>  {
        let aux = [];
        let count = 0;
        let isMounted = true;

        window.scrollTo(0, 0);

        PACK_DROPS_LIST.forEach((drop_id, index) => {
            getDropInfo(
                parseInt(drop_id),
                (info) => { aux[index] = info; }
            ).then(() => {
                if (!isMounted) {return;}
                count === PACK_DROPS_LIST.length - 1 ? setPacksInfoList(aux) : count += 1;
            });
        });
        return () => { isMounted = false; };
    }, []);

    useEffect(() => {
        // TODO: review this
        for (let packInfo of packsInfoList) {
            getInfoFromTemplateId(packInfo.template_id, (value) => {

                setPacksImages(currentValue => {
                    let newPacksImages = { ...currentValue };
                    newPacksImages[packInfo.template_id] = value;
                    return newPacksImages;
                });
            });
        }
    }, [packsInfoList]);

    useEffect(() => {
        autoRefresh();

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const loadTransactions = () => {
        getTransactionsStatus(getBrowserContext(), setData);
    };

    const autoRefresh = () => {
        loadTransactions();
        timeout = setTimeout(autoRefresh, 15000);
    };

    const renderTableData = () => {
        return data.map((d) => {
            return <FiatTransactionCard key={d.transaction_id} transaction={d} packInfos={packsInfoList} packsImages={packsImages} />;
        });
    };

    return (
        <div>
            { data.length > 0 ?
                renderTableData() :
                <div style={{ display: "flex", justifyContent: "center", margin: "16px", textWeight: 700 }}>No transactions found!</div> }
        </div>
    );
}

export default FiatHistory;
