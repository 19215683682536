import React from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Countdown from '@components/Countdown';
import STRINGS from '@utils/strings';
import IsReleased from '@components/IsReleased';

const Container = styled.div(({ theme, IsReleased }) => {
    let releasedStyle = IsReleased ? { textTransform: 'uppercase', padding: theme.spacing.xs } : { padding: theme.spacing.xxs };

    return {
        ...theme.typography.h3,
        width: '100%',
        color: theme.colors.common.dark,
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.colors.common.white,
        ...releasedStyle
    };
});

function Ribbon() {
    const theme = useTheme();

    return (
        <Container IsReleased={IsReleased()}>
            {IsReleased() ? STRINGS.ribbon : <Countdown />}
        </Container>
    );
}

export default Ribbon;
