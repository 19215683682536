import styled from '@emotion/styled';

const StyledButton = styled.button(({ secondary, theme, style }) => ({
    ...theme.typography.p,
    color: secondary ? theme.colors.primary.contrastText : theme.colors.common.white,
    backgroundColor: secondary ? theme.colors.primary.main : theme.colors.secondary.main,
    padding: `${theme.spacing.xxxs} ${theme.spacing.s}`,
    border: 'none',
    borderRadius: theme.borderRadius.s,
    opacity: 1,
    transition: theme.transition.default,
    '&:hover': {
        color: secondary ? theme.colors.primary.contrastText : theme.colors.common.white,
        backgroundColor: secondary ? theme.colors.primary.dark : theme.colors.secondary.dark,
        cursor: 'pointer'
    },
    '&:disabled': {
        filter: 'grayscale(1)',
        cursor: 'not-allowed'
    },
    ...style
}));

function Button({ children, primary, secondary, text, ...otherProps }) {
    return <StyledButton secondary={secondary} {...otherProps}>{children}</StyledButton>;
}

export default Button;
