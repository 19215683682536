import { FIAT_IFRAME_SERVER } from '@utils/globals';

export async function getTransactionsStatus(browserContext, success = () => { }, error = () => { }) {
    const request = {
        method: "GET",
        headers: { "Content-Type": "application/json;", "Access-Control-Allow-Origin": "*" }
    };

    try {
        fetch(`${FIAT_IFRAME_SERVER}/fiat/v1/status/${browserContext}`, request)
            .then(res => res.json())
            .then(result => {
                if (result) {
                    success(result);
                } else {
                    error("No response.");
                }
            });
    } catch (e) {
        console.error(e);
        error(e.message);
    }
}
