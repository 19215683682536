import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { getDropInfo } from '@api/api';
import { PACK_DROPS_LIST, WAX_SIGN_IN } from '@utils/globals';
import IsReleased from '@components/IsReleased';
import ErrorModal from '@components/ErrorModal';
import Ribbon from '@components/Ribbon';
import Pack from '@context/Shop/Pack';
import Loading from '@components/Loading';
import STRINGS from '@utils/strings';
import RES from '@utils/resources';
import { withUAL } from 'ual-reactjs-renderer';

import atomicHubLogo from '@images/atomichub.png';
import waxStashLogo from '@images/waxStash.png';
import nftHiveLogo from '@images/nftHive.svg';

const Container = styled.div(({ theme }) => ({
    paddingTop: theme.spacing.m,
    display: 'block',
    textAlign: 'center',
    [theme.mediaQuery.tabletLandscapeUp]: {
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'space-evenly'
    }
}));

const PackDetailsDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.m,
    color: theme.colors.common.dark,
    maxWidth: '65ch'
}));

const Logo = styled.img(({ theme }) => ({
    width: '40vw',
    marginBottom: theme.spacing.l,
    objectFit: 'contain',
    userSelect: 'none',
    [theme.mediaQuery.tabletLandscapeUp]: {
        width: '30vw'
    }
}));

const SecondaryMarketContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `${theme.spacing.xl} auto`,
    padding: theme.spacing.xs,
    width: 'max-content',
    color: theme.colors.primary.contrastText,
    backgroundColor: theme.colors.primary.main,
    borderRadius: theme.borderRadius.m
}));

const SecondaryMarketLogosContainer = styled.div(({ theme }) => ({
    width: theme.size.secondaryMarketContainerWidth,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
}));

const Wrapper = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: 'auto',
    maxWidth: theme.size.maxContentWidth
}));

const SecondaryMarketImage = styled.img(({ theme }) => ({
    padding: theme.spacing.xs,
    width: theme.size.secondaryMarketLogosWidth,
    objectFit: 'contain'
}));

function BuyPacks({ ual }) {
    const isReleased = IsReleased();

    const [ packsInfoList, setPacksInfoList ] = useState([]);
    const [ errorMessage, setErrorMessage ] = useState("");

    useEffect(() =>  {
        let aux = [];
        let count = 0;
        let isMounted = true;

        window.scrollTo(0, 0);

        PACK_DROPS_LIST.forEach((drop_id, index) => {
            getDropInfo(
                parseInt(drop_id),
                (info) => { aux[index] = info; },
                (errorMessage) => setErrorMessage(errorMessage)
            ).then(() => {
                if (!isMounted) {return;}
                count === PACK_DROPS_LIST.length - 1 ? setPacksInfoList(aux) : count += 1;
            });
        });
        return () => { isMounted = false; };
    }, []);

    const isAnySoldOut = packsInfoList.forEach((item) => {
        if (item &&  item.available === 0) {
            return true;
        }
    });

    const renderSecondaryMarketLinks = () => {
        return isAnySoldOut ? (
            <SecondaryMarketContainer>
                <h2>{STRINGS.secondaryMarketTitle}</h2>
                <SecondaryMarketLogosContainer>
                    <a href={RES.secondaryMarketLinks.nftHive.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={nftHiveLogo} alt={RES.secondaryMarketLinks.nftHive.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.atomicHub.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={atomicHubLogo} alt={RES.secondaryMarketLinks.atomicHub.label} />
                    </a>
                    <a href={RES.secondaryMarketLinks.waxStash.link} target='_blank' rel='noreferrer noopener'>
                        <SecondaryMarketImage src={waxStashLogo} alt={RES.secondaryMarketLinks.waxStash.label} />
                    </a>
                </SecondaryMarketLogosContainer>
            </SecondaryMarketContainer>
        ): null;
    };

    const renderPacks = packsInfoList.map((packInfo, index) => <Pack packInfo={packInfo} key={index} />);

    return (
        packsInfoList.length > 0 ?
            <>
                {
                    isReleased ? "" : <Ribbon />
                }
                <Wrapper>
                    { renderPacks }
                </Wrapper>
                {isReleased ? renderSecondaryMarketLinks() : null}
                <Container>
                    <Logo alt={STRINGS.campaign} src={RES.images.logo} />
                </Container>
                <ErrorModal show={errorMessage !== ""} onClose={() => setErrorMessage("")} >
                    {errorMessage}
                </ErrorModal>
            </>
            :
            <Loading />
    );
}

export default WAX_SIGN_IN ? withUAL(BuyPacks) : BuyPacks;
