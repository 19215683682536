const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export const RES = {
    images: {
        logo: 'https://facings.fra1.digitaloceanspaces.com/nftrippy/mushy-headline.svg',

        pack: 'https://nftrippy.fra1.digitaloceanspaces.com/standard-pack.png',
        box: 'https://nftrippy.fra1.digitaloceanspaces.com/mega-pack.png',
        packAndBox: 'https://nftrippy.fra1.digitaloceanspaces.com/packs.png',

        atomicHubLogo: '@images/atomichub.png',
        waxStashLogo: '@images/waxStash.png',
        nftHiveLogo: '@images/nftHive.svg',

        background01: 'https://nftrippy.fra1.digitaloceanspaces.com/blue.svg',
        background02: 'https://nftrippy.fra1.digitaloceanspaces.com/purple.svg',

        shroomMan: 'https://facings.fra1.digitaloceanspaces.com/nftrippy/ShroomMan.gif',
        maps: 'https://facings.fra1.digitaloceanspaces.com/nftrippy/maps.png'
    },

    openingAnimations: [
        {
            template_id: IS_PRODUCTION ? '287180' :'160369',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/nftrippy/single%20opening_1.mp4'
        },
        {
            template_id: IS_PRODUCTION ? '287181' : '160370',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/nftrippy/standard%20opening_1.mp4'
        },
        {
            template_id: IS_PRODUCTION ? '287182' : '160371',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/nftrippy/cluster%20opening_1.mp4'
        },
        {
            template_id: IS_PRODUCTION ? '287183' : '160372',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/nftrippy/elder%20opening_1.mp4'
        },
        {
            template_id: 'craft',
            openingAnimation: 'https://facings.fra1.digitaloceanspaces.com/nftrippy/crafting_1.mp4'
        }
    ],

    samples: [
        {
            srcImg: 'https://ipfs.io/ipfs/QmNpipDa2BdA8DjC746ZsFpMHtmEvkk8zjsyzs9AvVWE3R',
            caption: 'Baseline',
            textColor: '#b07c33'
        },
        {
            srcImg: 'https://ipfs.io/ipfs/QmdjR7hgwUQzMLy3KrZwrNcTbWcaYHteSYtqX8G8Dykrmb',
            caption: 'Lift Off',
            textColor: '#656085'
        },
        {
            srcImg: 'https://ipfs.io/ipfs/Qmck4JAY9GkvDv75oVzTvJFizo1fKaQo2SCWo3ZsjGzQ5u',
            caption: 'Heroic',
            textColor: '#a97fe6',
            type: 'video'
        },
        {
            srcImg: 'https://ipfs.io/ipfs/QmYdxCvQvbp8n8TLuWmE38P6DVQP1jH4pLbTRmZLGfnFy1',
            caption: 'Psilocybin',
            textColor: '#00D5BA'
        },
        {
            srcImg: 'https://ipfs.io/ipfs/QmSZH4ic2SXbcjzfCQ4EXxKWyCcq9kJEhVVdBH3MJTgpBB',
            caption: 'Psilocin',
            textColor: '#797EB3'
        },
        {
            srcImg: 'https://ipfs.io/ipfs/QmdA5UnLHznQnc3UpoCc6yV74qGQ5T9T2cjCLkNmwC4Q59',
            caption: 'Baeocystin',
            textColor: '#F147D5'
        }
    ],

    secondaryMarketLinks: {
        atomicHub: {
            label: "AtomicHub",
            link: "https://wax.atomichub.io/market?collection_name=nftrippy"
        },
        atomicHubInventory: {
            label: "AtomicHub Inventory",
            link: "https://wax.atomichub.io/profile/{0}?collection_name=nftrippy"
        },
        atomicHubEntireCollection: {
            label: "AtomicHub Dark Pinup entire Collection",
            link: "https://wax.atomichub.io/tools/book/nftrippy/{0}"
        },
        waxStash: {
            label: "WAXStash",
            link: "https://www.waxstash.com/markets?collection_name=nftrippy"
        },
        nftHive: {
            label: "NFTHive",
            link: "https://nfthive.io/collection/nftrippy"
        }
    },

    externalLinks: {
        wax: {
            label: "Powered by WAX",
            link: "https://wax.io"
        },
        facings: {
            label: "Built by FACINGS",
            link: "https://facings.io"
        },
        facingsTelegram: {
            label: "FACINGS telegram group",
            link: "https://t.me/facings"
        },
        facingsDiscord: {
            label: "FACINGS discord channel",
            link: "https://discord.gg/mW2654Nw3Z"
        },
        facingsTwitter: {
            label: "FACINGS twitter",
            link: "https://twitter.com/FACINGSofficial"
        },
        atomicAssets: {
            label: "Built with Atomic Assets",
            link: "https://wax.atomichub.io/"
        },
        nftrippyTelegram: {
            label: "NFTrippy telegram group",
            link: "https://t.me/NFTrippy"
        }
    },

    packs: [
        {
            srcImg: 'QmP1EhAGdYXiczQZaMpzdGwrUtrrWJNA63WC1eHC54cLUY',
            caption: 'Single Pack',
            quantity: '1584 Sold',
            description: 'Single Pack of 1 NFT',
            probabilities:
            [
                { rarity: 'Compound/Element 1 - Psilocybin', probability: '0%' },
                { rarity: 'Compound/Element 1 - Psilocin', probability: '0%' },
                { rarity: 'Compound/Element 1 - Baeocystin', probability: '0%' },
                { rarity: 'Baseline', probability: '90%' },
                { rarity: 'Lift Off', probability: '7.2%' },
                { rarity: 'Heroic', probability: '2.8%' },
                { rarity: 'Spore Print Ticket', probability: '0%' },
                { rarity: 'Elder Cards', note: 'pack needs to be crafted', probability: '0%' }
            ]
        },
        {
            srcImg: 'QmZnrzRM1tFoD8LFGqnczYwJsKwkj1DEVoAhR3fh7p5VfQ',
            caption: "Standard Pack",
            quantity: '391 Sold',
            description: 'Standard Pack of 12 NFTs',
            probabilities:
            [
                { rarity: 'Compound/Element 1 - Psilocybin', probability: '25%' },
                { rarity: 'Compound/Element 1 - Psilocin', probability: '25%' },
                { rarity: 'Compound/Element 1 - Baeocystin', probability: '25%' },
                { rarity: 'Baseline', probability: '22.5%' },
                { rarity: 'Lift Off', probability: '1.8%' },
                { rarity: 'Heroic', probability: '0.7%' },
                { rarity: 'Spore Print Ticket', probability: '0%' },
                { rarity: 'Elder Cards', note: 'pack needs to be crafted', probability: '0%' }
            ]
        },
        {
            srcImg: 'QmcGqFnC6R2J4phbGmZWkjHcA2FBTXUk2CjHG5edQVRvpE',
            caption: "Cluster Pack",
            quantity: '526 Sold',
            description: 'Cluster Pack of 42 NFTs',
            probabilities:
            [
                { rarity: 'Compound/Element 1 - Psilocybin', probability: '23.81%' },
                { rarity: 'Compound/Element 1 - Psilocin', probability: '23.81%' },
                { rarity: 'Compound/Element 1 - Baeocystin', probability: '23.81%' },
                { rarity: 'Baseline', probability: '25.71%' },
                { rarity: 'Lift Off', probability: '2.06%' },
                { rarity: 'Heroic', probability: '0.8%' },
                { rarity: 'Spore Print Ticket', probability: '100%' },
                { rarity: 'Elder Cards', note: 'pack needs to be crafted', probability: '0%' }
            ]
        }
    ]
};

export default RES;
