import styled from '@emotion/styled';
import Modal from '@components/Modal';
import Button from '@components/Button';
import STRINGS from '@utils/strings';

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h6,
    marginBottom: theme.spacing.xs,
    display: 'flex',
    justifyContent: 'center'
}));

const ButtonContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: 'auto',
    marginTop: theme.spacing.s
}));

function ErrorModal({ show, onClose, children }) {
    return (<>
        <Modal show={show} onClose={onClose}>
            <Title>{STRINGS.error}</Title>
            {children}
            <ButtonContainer>
                <Button onClick={onClose}>{STRINGS.close}</Button>
            </ButtonContainer>
        </Modal>
    </>);
}

export default ErrorModal;
