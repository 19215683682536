import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import STRINGS from '@utils/strings';
import CraftRecipe from '@components/CraftRecipe';
import Loading from '@components/Loading';
import { getRecipes, getInventory } from '@api/api';
import Container from '@components/Container';
import { withUAL } from 'ual-reactjs-renderer';

const Header = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.colors.primary.contrastText
}));

const Description = styled.div(({ theme }) => ({
    ...theme.typography.p,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.xs,
    textAlign: 'center',
    'ol': {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing.xs,
        textAlign: 'left'
    }
}));

function Craft({ ual }) {
    const theme = useTheme();
    const [ recipes, setRecipes ] = useState([]);
    const [ inventory, setInventory ] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (ual.activeUser) {
            loadInventory();
            getRecipes(setRecipes);
        }
    }, []);

    const loadInventory = () => {
        getInventory(ual.activeUser.accountName, (assets) => { setInventory(assets); });
    };

    const renderRecipes = recipes.map((recipe, index) => {
        return <CraftRecipe recipe={recipe} inventory={inventory} key={index} />;
    });

    return (
        recipes.length > 0 ?
            <Container direction="column">
                <Container
                    margin={`${theme.spacing.m} ${theme.spacing.xs}`}
                    style={{
                        color: theme.colors.secondary.dark
                    }}
                    isFullWidth={false}
                >
                    <Header>{STRINGS.craftPage.craftCard}</Header>
                </Container>
                <Container
                    isFullWidth={false}
                    padding={theme.spacing.xs}
                    margin={`${theme.spacing.s} auto`}
                    style={{
                        maxWidth: '80ch',
                        border: `1px solid ${theme.colors.primary.light}`,
                        borderRadius: theme.borderRadius.m
                    }}
                >
                    <Description>{STRINGS.craftPage.description}</Description>
                </Container>
                <Container direction="column">
                    {renderRecipes}
                </Container>
            </Container>
            :
            <Loading />
    );
}

export default withUAL(Craft);
