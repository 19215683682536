import styled from '@emotion/styled';
import RES from '@utils/resources';
import Container from '@components/Container';
import { useHistory } from 'react-router';
import ROUTES from '@utils/routes';
import STRINGS from '@utils/strings';

import PoweredByWax from '@images/PoweredByWax.png';
import AtomicAssetsLogo from '@images/atomic-assets-logo.png';
import FacingsLogo from '@images/FACINGSLogoNegative.png';
import TelegramLogo from '@images/telegram-icon.svg';
import TwitterLogo from '@images/twitter-icon.svg';
import DiscordLogo from '@images/discord.svg';

const StyledFooter = styled.div(({ theme, style }) => ({
    backgroundColor: theme.colors.secondary.main,
    padding: theme.spacing.xs,
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing.xs,
    ...style
}));

const Row = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));

const SocialMediaLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
    margin: theme.spacing.xxs
}));

const FacingsGroup = styled.div(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const FooterLink = styled.a(({ theme }) => ({
    cursor: 'pointer',
    marginTop: theme.spacing.s,
    userSelect: 'none',
    [theme.mediaQuery.tabletUp]: {
        marginTop: 0
    }
}));

const TermsLink = styled.p(({ theme }) => ({
    cursor: 'pointer',
    color: theme.colors.common.white,
    marginTop: theme.spacing.s,
    [theme.mediaQuery.tabletUp]: {
        marginTop: 0
    }
}));

function Footer({ style }) {
    const history = useHistory();

    const goToTerms = () => {
        history.push(ROUTES.terms);
        window.scrollTo(0, 0);
    };

    return (
        <StyledFooter>
            <Container justifyContent="space-evenly">
                <FacingsGroup>
                    <FooterLink href={RES.externalLinks.nftrippyTelegram.link} target="_blank" rel="noopener noreferrer">
                        <img style={{ width: '60px', borderRadius: '50%', padding: '8px', background: '#d7c5f5' }} src={RES.images.logo} alt={RES.externalLinks.nftrippyTelegram.label} />
                    </FooterLink>
                    <Row>
                        <SocialMediaLink href={RES.externalLinks.nftrippyTelegram.link} target="_blank" rel="noopener noreferrer">
                            <img style={{ width: '24px' }} src={TelegramLogo}  alt={RES.externalLinks.nftrippyTelegram.label} />
                        </SocialMediaLink>
                    </Row>
                </FacingsGroup>
                <FooterLink href={RES.externalLinks.wax.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '150px' }} src={PoweredByWax}  alt={RES.externalLinks.wax.label} />
                </FooterLink>
                <FooterLink href={RES.externalLinks.atomicAssets.link} target="_blank" rel="noopener noreferrer">
                    <img style={{ width: '150px' }} src={AtomicAssetsLogo} alt={RES.externalLinks.atomicAssets.label} />
                </FooterLink>
                <FacingsGroup>
                    <FooterLink  href={RES.externalLinks.facings.link} target="_blank" rel="noopener noreferrer">
                        <img style={{ width: '70px', margin: '0 25px' }} src={FacingsLogo} alt={RES.externalLinks.facings.label} />
                    </FooterLink>
                    <Row>
                        <SocialMediaLink href={RES.externalLinks.facingsTelegram.link} target="_blank" rel="noopener noreferrer">
                            <img style={{ width: '24px' }} src={TelegramLogo}  alt={RES.externalLinks.facingsTelegram.label} />
                        </SocialMediaLink>
                        <SocialMediaLink href={RES.externalLinks.facingsTwitter.link} target="_blank" rel="noopener noreferrer">
                            <img style={{ width: '24px' }} src={TwitterLogo}  alt={RES.externalLinks.facingsTwitter.label} />
                        </SocialMediaLink>
                        <SocialMediaLink href={RES.externalLinks.facingsDiscord.link} target="_blank" rel="noopener noreferrer">
                            <img style={{ width: '24px' }} src={DiscordLogo}  alt={RES.externalLinks.facingsDiscord.label} />
                        </SocialMediaLink>
                    </Row>
                </FacingsGroup>
            </Container>
            <TermsLink onClick={() => goToTerms()}>{STRINGS.termsPage.termsAndConditions}</TermsLink>
        </StyledFooter>
    );
}

export default Footer;
