const IS_PRODUCTION = process.env.NODE_ENV === 'production';

let templateInfo = new Map();

export const getInfo = (templateId) => {
    return templateInfo.get(templateId);
};

export const setInfo = (templateId, info) => {
    return templateInfo.set(templateId, info);
};

if (IS_PRODUCTION) {
    // TODO: Update template ID
    setInfo(287180, {
        imgHash: 'QmP1EhAGdYXiczQZaMpzdGwrUtrrWJNA63WC1eHC54cLUY',
        name: 'Single Pack',
        isImg: true,
        variant: ''
    });
    setInfo(287181, {
        imgHash: 'QmZnrzRM1tFoD8LFGqnczYwJsKwkj1DEVoAhR3fh7p5VfQ',
        name: 'Standard Pack',
        isImg: true,
        variant: ''
    });
    setInfo(287182, {
        imgHash: 'QmcGqFnC6R2J4phbGmZWkjHcA2FBTXUk2CjHG5edQVRvpE',
        name: 'Cluster Pack',
        isImg: true,
        variant: ''
    });
    setInfo(287183, {
        imgHash: 'QmRjxhQ5qvnP21n4GFWVBrQECdsifTW7vyBqxv4GMss3rt',
        name: 'Elder Pack',
        isImg: true,
        variant: ''
    });

} else {
    setInfo(160369, {
        imgHash: 'QmP1EhAGdYXiczQZaMpzdGwrUtrrWJNA63WC1eHC54cLUY',
        name: 'Single Pack',
        isImg: true,
        variant: ''
    });
    setInfo(160370, {
        imgHash: 'QmZnrzRM1tFoD8LFGqnczYwJsKwkj1DEVoAhR3fh7p5VfQ',
        name: 'Standard Pack',
        isImg: true,
        variant: ''
    });
    setInfo(160371, {
        imgHash: 'QmcGqFnC6R2J4phbGmZWkjHcA2FBTXUk2CjHG5edQVRvpE',
        name: 'Cluster Pack',
        isImg: true,
        variant: ''
    });
    setInfo(160372, {
        imgHash: 'QmRjxhQ5qvnP21n4GFWVBrQECdsifTW7vyBqxv4GMss3rt',
        name: 'Elder Pack',
        isImg: true,
        variant: ''
    });
}
