import STRINGS from '@utils/strings';
import styled from '@emotion/styled';
import RES from '@utils/resources';
import Button from '@components/Button';
import { isReleased } from '@utils/globals';

const ContentContainer =  styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors.common.dark,
    textAlign: 'left',
    gap: theme.spacing.xxs,
    [ theme.mediaQuery.largeMobileOnly ]: {
        textAlign: 'center',
        alignItems: 'center'
    }
}));

const Title =  styled.h3(({ theme }) => ({
    color: theme.colors.primary.dark,
    fontWeight: 600,
    fontSize: '1.5rem',
    textTransform: 'uppercase'
}));

const Subtitle =  styled.p(({ theme }) => ({
    fontSize: '1rem',
    fontWeight: 600
}));

const Description =  styled.p(() => ({
    lineHeight: '180%',
    fontSize: '1rem',
    fontWeight: 300,
    margin: '1rem 1rem 1rem 0'
}));

const Image = styled.img(({ theme }) => ({
    width: 260,
    objectFit: 'contain',
    margin: '1rem',
    [ theme.mediaQuery.largeMobileUp ]: {
        margin: '2rem'
    }
}));

const ButtonStyle = {
    width: 'fit-content',
    textTransform: 'uppercase',
    letterSpacing: '0.1rem',
    '&:disabled': {
        opacity: '75%',
        cursor: 'not-allowed'
    }
};

const SpotlightStyle =  styled.div(({ theme, invert }) => ({
    display: 'flex',
    flexDirection: invert ? 'row-reverse' : 'row',
    color: theme.colors.common.dark,
    maxWidth: '50rem',
    gap: theme.spacing.xl,
    alignItems: 'center',
    padding: theme.spacing.xxs,
    marginBottom: theme.spacing.xxl,
    [ theme.mediaQuery.largeMobileOnly ]: {
        flexDirection: 'column',
        padding: '0 16px',
        gap: theme.spacing.m
    }
}));

function Spotlight({ invert, image, title, subtitle, description, button, buttonCallback, buttonDisabled=false }) {
    return (
        <SpotlightStyle invert={invert}>
            { image ? <Image src={image} alt="image" /> : null }
            <ContentContainer>
                { title ? <Title>{title}</Title> : null }
                { subtitle ? <Subtitle>{subtitle}</Subtitle> : null }
                { description ? <Description dangerouslySetInnerHTML={{ __html: description }} /> : null }
                { button ? <Button disabled={buttonDisabled} style={ButtonStyle} onClick={buttonCallback}>{button}</Button> : null }
            </ContentContainer>
        </SpotlightStyle>
    );
}

export default Spotlight;