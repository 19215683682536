/* eslint import/extensions: "off" */

import React, { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import Button from '@components/Button';
import StyledFormModal from '@components/FormModal';
import Grid from '@components/Grid';
import FiatIframe from '@components/Fiat/FiatIframe';
import STRINGS from '@utils/strings';
import RES from '@utils/resources';
import IsReleased from '@components/IsReleased';
import Spotlight from '@components/Spotlight';
import { IPFS_ENDPOINT } from '@utils/globals';
import Container from '@components/Container';

const HeaderContainer = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    background: theme.colors.primary.light,
    backgroundSize: 'auto',
    backgroundPositionX: 'center',
    height: '100%',
    borderRadius: theme.borderRadius.l,
    margin: `${theme.spacing.xl} ${theme.spacing.xs} ${theme.spacing.xxl}`,
    [theme.mediaQuery.largeMobileUp]: {
        margin: `${theme.spacing.xl} ${theme.spacing.s} ${theme.spacing.xxl}`
    },
    [theme.mediaQuery.tabletLandscapeUp]: {
        margin: `${theme.spacing.xl} ${theme.spacing.l} ${theme.spacing.xxl}`
    }
}));

const HeaderInfo = styled.div(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    gap: `${theme.spacing.l}`,
    padding: `${theme.spacing.xxl} ${theme.spacing.s}`,
    [theme.mediaQuery.mobileOnly]: {
        padding: `${theme.spacing.xxl} ${theme.spacing.s} ${theme.spacing.s}`
    }
}));

const HeaderText = styled.div(({ theme }) => ({
    ...theme.typography.bubblegum,
    color: theme.colors.common.white,
    maxWidth: '15ch',
    textAlign: 'center',
    fontSize: '4.5rem',
    textShadow: `4px 4px 4px ${theme.colors.primary.main}`,
    [theme.mediaQuery.mobileOnly]: {
        fontSize: '3rem'
    },
    [theme.mediaQuery.largeMobileOnly]: {
        fontSize: '3.5rem'
    }
}));

const LogoImage = styled.img(() => ({
    objectFit: 'contain',
    width: '55vw',
    maxWidth: 350
}));

const InfoContainer = styled.div(({ theme }) => ({
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    gap: `${theme.spacing.l}`,
    padding: `${theme.spacing.m} ${theme.spacing.xs} ${theme.spacing.xxl}`
}));

const TextInfo = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '65vw',
    textAlign: 'center',
    marginBottom: `${theme.spacing.l}`,
    color: theme.colors.common.dark,
    [theme.mediaQuery.mobileOnly]: {
        maxWidth: '90vw'
    }
}));

const TextDonations = styled.p(({ theme }) => ({
    ...theme.typography.p,
    maxWidth: '65vw',
    textAlign: 'center',
    marginBottom: `${theme.spacing.xs}`,
    color: theme.colors.common.dark,
    [theme.mediaQuery.mobileOnly]: {
        maxWidth: '90vw'
    }
}));

const Header = styled.h4(({ theme }) => ({
    ...theme.typography.h4,
    fontWeight: '700',
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.colors.primary.dark
}));

const ComingSoon = styled.h3(({ theme }) => ({
    ...theme.typography.h3,
    color: theme.colors.primary.main,
    position: 'absolute',
    top: 0,
    transform: 'translateY(-50%)',
    fontSize: '32px',
    background: 'white',
    textTransform: 'uppercase',
    borderRadius: '20px',
    padding: '20px 200px',
    [theme.mediaQuery.mobileOnly]: {
        padding: `${theme.spacing.xs} ${theme.spacing.l}!important`,
        fontSize: '22px'
    },
    [theme.mediaQuery.largeMobileOnly]: {
        padding: `${theme.spacing.xs} ${theme.spacing.xxl}`
    }
}));

const Image = styled.img(({ theme }) => ({
    height: '50vh',
    maxHeight: '600px',
    objectFit: 'contain',
    [ theme.mediaQuery.mobileOnly ]: {
        maxHeight: '300px'
    }
}));

const PackDescription = styled.p(({ theme }) => ({
    ...theme.typography.p,
    color: theme.colors.primary.dark,
    fontWeight: 'bold'
}));

const PackDetailsTitle = styled.h5(({ theme }) => ({
    ...theme.typography.h5,
    color: theme.colors.primary.dark,
    fontWeight: 'bold',
    marginBottom: theme.spacing.xs
}));

const PackDetailsList = styled.ul(({ theme }) => ({
    ...theme.typography.p,
    marginTop: theme.spacing.xxs,
    color: theme.colors.common.dark,
    listStyle: 'none',
    textAlign: 'left',
    padding: 0,
    [ theme.mediaQuery.tabletUp]: {
        width: 395
    }
}));

const PackDetailItem = styled.li(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    borderBottom: `thin solid ${theme.colors.primary.main}69`
}));

function Home() {
    const theme = useTheme();
    const isReleased = IsReleased();

    const [ showFormModal, setShowFormModal ] = useState(false);

    const CustomGrid = {
        marginBottom: theme.spacing.m,
        gridRowGap: theme.spacing.xl,
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: '1fr'
        },
        [theme.mediaQuery.largeMobileUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            'img': {
                width: 270
            }
        },
        [theme.mediaQuery.tabletLandscapeUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)'
        },
        [theme.mediaQuery.desktopUp]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
            'img': {
                width: 270
            }
        }
    };

    const buttonStyle = {
        ...theme.typography.h4,
        color: theme.colors.common.white,
        position: 'absolute',
        bottom: 0,
        transform: 'translateY(50%)',
        padding: '16px',
        [theme.mediaQuery.mobileOnly]: {
            position: 'relative !important'
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <HeaderContainer>
                { isReleased ? <ComingSoon>{STRINGS.comingSoon}</ComingSoon> : <ComingSoon>{STRINGS.availableNow}</ComingSoon> }
                <HeaderInfo>
                    <LogoImage alt={STRINGS.campaign} src={RES.images.logo} />
                    <HeaderText>{STRINGS.headline}</HeaderText>
                </HeaderInfo>
                <Button
                    onClick={() => { setShowFormModal(true); }}
                    style={buttonStyle}
                >
                    {STRINGS.formButtonPostRelease}
                </Button>
            </HeaderContainer>
            <FiatIframe />
            <InfoContainer>
                <Header>{STRINGS.rarities}</Header>
                <Grid items={RES.samples} style={CustomGrid} />
                <TextInfo>{STRINGS.mushroomInfo}</TextInfo>
                <Header>Pack Sales</Header>
                <Container  gap={theme.spacing.xxl} padding={theme.spacing.l} wrap="wrap">
                    { RES.packs.map((pack, index) => (
                        <Container key={index} direction="column" gap={theme.spacing.l} style={{ maxWidth: '400px' }}>
                            <Image src={`${IPFS_ENDPOINT}${pack.srcImg}`} alt={pack.caption} />
                            <Container direction="column">
                                <PackDescription style={{ fontSize: '22px' }}>{pack.description}</PackDescription>
                                <PackDescription style={{ textTransform: 'uppercase' }}>{pack.quantity}</PackDescription>
                            </Container>
                            <Container direction="column" alignItems="center">
                                <PackDetailsTitle>Pack Probabilities</PackDetailsTitle>
                                <PackDetailsList>
                                    {pack.probabilities &&
                                        pack.probabilities.map((line, index) => (
                                            <PackDetailItem key={index}>
                                                <span>
                                                    {line.rarity} { line.note ? <span style={{ ...theme.typography.pTiny, marginRight: theme.spacing.xxs }}> ({line.note}) </span> : null }
                                                </span>
                                                <span>
                                                    {line.probability}
                                                </span>
                                            </PackDetailItem>
                                        ))
                                    }
                                </PackDetailsList>
                            </Container>
                        </Container>
                    )) }
                </Container>
                <Spotlight
                    image={RES.images.shroomMan}
                    title={STRINGS.masterForager}
                    description={STRINGS.masterForagerInfo}
                />
                <Header>{STRINGS.mapsTitle}</Header>
                <TextDonations>{STRINGS.mapsDescription}</TextDonations>
                <LogoImage src={RES.images.maps} />
            </InfoContainer>
            <StyledFormModal
                show={showFormModal}
                onClose={() => setShowFormModal(false)}
                title={STRINGS.formTitle}
                portalId='19612576'
                formId='0771204f-eaa4-41ff-b2bf-69fb25c7bd76'
            />
        </>
    );
}

export default Home;
