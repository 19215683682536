import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import Modal from '@components/Modal';
import Button from '@components/Button';
import { H5 } from '@styles/typography';
import checkIcon from '@images/check.svg';

const Title = styled.div(({ theme }) => ({
    ...theme.typography.h5,
    marginBottom: theme.spacing.xs,
    display: 'flex',
    justifyContent: 'center'
}));

const ButtonContainer = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    margin: 'auto',
    marginTop: theme.spacing.s
}));

function SuccessModal({ show, onClose, onConfirm, title, children }) {
    return (<>
        <Modal show={show} onClose={onClose}>
            <img style={{ height: '60px' }} src={checkIcon} />
            <Title>{title}</Title>
            {children}
            <ButtonContainer>
                <Button onClick={onConfirm}>OK</Button>
            </ButtonContainer>
        </Modal>
    </>);
}

export default SuccessModal;
