import styled from '@emotion/styled';
import { useState } from 'react';

const ItemContainer = styled.div(({ theme }) => ({
    margin: theme.spacing.s,
    transition: theme.transition.default
}));

const Question = styled.h4(({ theme, isOpen }) => ({
    ...theme.typography.h4,
    color: isOpen? theme.colors.secondary.main : theme.colors.common.black,
    marginBottom: theme.spacing.xs,
    transition: theme.transition.default,
    '&:hover': {
        color: theme.colors.secondary.main,
        cursor: 'pointer'
    }
}));

const Answer = styled.div(({ theme, isOpen }) => ({
    ...theme.typography.p,
    marginBottom: theme.spacing.xs,
    overflow: 'hidden',
    height: isOpen ? 'auto' : '0',
    transition: theme.transition.default,
    color: theme.colors.common.dark,
    'a': {
        color: theme.colors.common.dark,
        '&:hover': {
            color: theme.colors.secondary.main
        }
    },
    'p': {
        marginBottom: theme.spacing.xs
    }
}));

function FAQItem({ question, answer }) {
    const [ isOpen, setIsOpen ] = useState(false);

    return (
        <ItemContainer>
            <Question isOpen={isOpen} onClick={() => setIsOpen(prevState => !prevState)}>{question}</Question>
            <Answer isOpen={isOpen}>{answer}</Answer>
        </ItemContainer>
    );
}

export default FAQItem;
