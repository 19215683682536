import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import x from '@images/close-icon.svg';
import { useEffect } from 'react';

const ModalBackgroundOverlay = styled.div(() => ({
    backgroundColor: 'rgba(6,2,1,0.5)',
    position: 'fixed',
    display: 'flex',
    verticalAlign: 'middle',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 999
}));

const StyledModal = styled.div(({ theme, style }) => ({
    ...theme.typography.h6,
    color: theme.colors.common.black,
    backgroundColor: theme.colors.common.white,
    padding: theme.spacing.s,
    border: `solid ${theme.colors.common.white} 1px`,
    borderRadius: theme.borderRadius.s,
    fontWeight: 'bold',
    width: '40vw',
    minWidth: '300px',
    margin: 'auto',
    textAlign: 'center',
    ...style
}));

const XCloseButtonContainer = styled.div(({ theme }) => ({
    marginLeft: 'auto',
    marginBottom: theme.spacing.xxs,
    cursor: 'pointer',
    width: theme.spacing.xs,
    color: theme.colors.primary.main,
    transition: theme.transition.default,
    '&:hover': {
        color: theme.colors.primary.dark
    }
}));

function Modal({ children, show, onClose, style, test, ...otherProps }) {

    useEffect(() => {
        document.body.style.overflow = show ? 'hidden' : 'auto';
    }, [show]);

    const theme = useTheme();

    return (<>
        {show ?
            <ModalBackgroundOverlay>
                <StyledModal {...otherProps} style={style} >
                    <XCloseButtonContainer onClick={onClose}>
                        <img style={{ width: theme.spacing.xs, height: theme.spacing.xs }} src={x} />
                    </XCloseButtonContainer>
                    {children}
                </StyledModal>
            </ModalBackgroundOverlay>
            :
            null}
    </>);
}

export default Modal;
