import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import GridItem from '@components/GridItem';
import ViewAsset from '@components/ViewAsset';

const Container = styled.div(({ theme, items, style }) => ({
    display: 'grid',
    gap: theme.spacing.s,
    gridAutoRows: '1fr',
    justifyItems: 'center',
    alignItems: 'center',
    gridTemplateColumns: '1fr',
    overflow: 'hidden',
    marginRight: theme.spacing.xs,
    marginLeft: theme.spacing.xs,
    padding: theme.spacing.xs,
    [theme.mediaQuery.largeMobileUp]: {
        gridTemplateColumns: `repeat(${Math.min(items.length, 2)}, 1fr)`
    },
    [theme.mediaQuery.tabletLandscapeUp]: {
        gridTemplateColumns: `repeat(${Math.min(items.length, 3)}, 1fr)`,
        marginRight: theme.spacing.l,
        marginLeft: theme.spacing.l
    },
    [theme.mediaQuery.desktopUp]: {
        gridTemplateColumns: `repeat(${Math.min(items.length, 4)}, 1fr)`
    },
    ...style
}));

function Grid({ items, canExpand, style }) {
    const [ isAssetOpened, setIsAssetOpened ] = useState(false);
    const [ assetToShow, setAssetToShow ] = useState(null);

    useEffect(() => {
        document.body.style.overflow = assetToShow ? 'hidden' : 'auto';
    }, [assetToShow]);

    function onAssetSelected(asset) {
        if (canExpand) {
            setIsAssetOpened(true);
            setAssetToShow(asset);
        }
    }

    function onAssetClosed() {
        setIsAssetOpened(false);
        setAssetToShow('');
    }

    let itemsToRender = items.map((item, index) => <GridItem key={index} onClick={onAssetSelected} {...item} />);

    return (
        <Container items={items} style={style}>
            {itemsToRender}
            { isAssetOpened ? <ViewAsset asset={assetToShow} onClick={onAssetClosed} /> : null }
        </Container>
    );
}

export default Grid;
